import * as React from "react";

const Contact = () => {
  return (
    <div id="Contact">
      <h2>nhantnguyen42&#8203;@gmail.com</h2>
    </div>
  );
};

export default Contact;
import * as React from "react";

const Blog = () => {
  return (
    <div id="Blog">
      <h1>'// TODO: Implement Blog API'.</h1>
      <p>Realistically, will arrive in a few weeks.</p>
    </div>
  );
};

export default Blog;
